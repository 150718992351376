import React, { Component } from "react";
import Cookies from "universal-cookie";
import Spreadsheet from "react-spreadsheet";

import withAuth from "../hoc/withAuth";
import MainLayout from "../components/layouts/MainLayout";
import SelectDealer from "../components/selects/SelectDealer";

import { otodisService } from "../services/OtodisService";
import { miscservices } from "../services/MiscServices";

import { Form, Input, Button, Modal, Row, Col } from "antd";
import { UploadOutlined, ReloadOutlined, RightCircleOutlined } from "@ant-design/icons";

class ServiceCostUploadInvoice extends Component {
  token = new Cookies().get("_bbn_tn");
  columnLabels = ["No Mesin", "Nominal Biaya"];

  constructor(props) {
    super(props);

    const { match } = this.props;
    const code_svb = match.params.code_svb;

    this.initState = {
      codeSVB: code_svb,
      DealerCode: "",
      invoiceNumber: "",
      linkTaxInvoice: "",
      TotalTaxDPP: 0,
      TotalTaxPPN: 0,
      loadingButton: false,
      dataSheet: [
        [{ value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }],
      ],
    };

    this.state = { ...this.initState };
  }

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  changeDealerCode = (v) => {
    this.setState({
      DealerCode: v,
    });
  };

  changeInvoiceNumber = (e) => {
    this.setState({
      invoiceNumber: e.target.value,
    });
  };

  changelinkTaxInvoice = (e) => {
    this.setState({
      linkTaxInvoice: e.target.value,
    });
  };

  onSheetChange = (newData) => {
    // if (newData !== this.state.dataSheet)
    this.setState({ dataSheet: newData });
  };

  onResetSheet = () => {
    this.setState({
      dataSheet: [
        [{ value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }],
        [{ value: "" }, { value: "" }],
      ],
    });
  };

  onFetchTaxData = async () => {
    const { linkTaxInvoice } = this.state;

    if (!linkTaxInvoice) {
      Modal.error({
        title: "Validation Error",
        content: "Link Tax Invoice Not Valid",
      });
      return false;
    }

    try {
      this.setState({
        loadingButton: true,
      });
      const fetchTaxData = await miscservices.getTaxInvoiceData(linkTaxInvoice);
      console.log("fetchTaxData", fetchTaxData);
      if (fetchTaxData.success === false) {
        this.setState({
          loadingButton: false,
        });
        Modal.error({
          title: "Process Error",
          content: fetchTaxData.error.message,
        });
      } else {
        console.log("TotalDpp", fetchTaxData.data.data.totalDpp);
        this.setState({
          loadingButton: false,
          TotalTaxDPP: Number(fetchTaxData.data.data.totalDpp),
          TotalTaxPPN: Number(fetchTaxData.data.data.totalPpn),
        });
      }
      // console.log(uploadInvoice.data.success.data);
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  onClickUploadData = async () => {
    const { codeSVB, invoiceNumber, linkTaxInvoice, DealerCode, dataSheet } = this.state;

    if (!DealerCode) {
      Modal.error({
        title: "Validation Error",
        content: "Dealer Not Valid",
      });
      return false;
    }
    if (!invoiceNumber) {
      Modal.error({
        title: "Validation Error",
        content: "Invoice Number Not Valid",
      });
      return false;
    }

    if (!linkTaxInvoice) {
      Modal.error({
        title: "Validation Error",
        content: "Link Tax Invoice Not Valid",
      });
      return false;
    }

    const dataCleaned = dataSheet
      .map((row) => {
        if (row[0]?.value !== "" && row[0]?.value !== undefined && row[1]?.value !== "" && row[1]?.value !== undefined && row[1]?.value > 0) {
          return {
            vehicle_engine_number: row[0]?.value,
            service_cost: row[1]?.value,
          };
        } else {
          return null;
        }
      })
      .filter((data) => data !== null);

    if (dataCleaned.length > 0) {
      try {
        this.setState({
          loadingButton: true,
        });
        const params = {
          service_bureau_code: codeSVB,
          dealer_code: DealerCode,
          invoice_number: invoiceNumber,
          link_tax_invoice: linkTaxInvoice,
          engine_list: dataCleaned,
        };

        otodisService.setToken(this.token);
        const uploadInvoice = await otodisService.UploadInvoiceServiceCost(params);
        console.log(JSON.stringify(params));
        // console.log(uploadInvoice.data.success.data);

        Modal.info({
          title: "Data Processed",
          width: 800,
          content: (
            <div className="table-responsive" style={{ height: 200 }}>
              <table className="table table-bordered table-sm" style={{ fontSize: "83%" }}>
                <tr>
                  <th>Order Code</th>
                  <th>No Mesin</th>
                  <th>Nominal</th>
                  <th>Keterangan</th>
                </tr>
                {uploadInvoice.data.error.data.map((row) => {
                  return (
                    <tr key={row.orderCode}>
                      <td>{row.order_code}</td>
                      <td>-</td>
                      <td>{row.service_cost}</td>
                      <td className="text-danger">FAILED - {row.description}</td>
                    </tr>
                  );
                })}
                {uploadInvoice.data.success.data.map((row) => {
                  return (
                    <tr key={row.orderCode}>
                      <td>{row.order_code}</td>
                      <td>{row.vehicle_engine_number}</td>
                      <td>{row.service_cost}</td>
                      <td className="text-success">SUCCESS</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          ),
        });

        this.onResetSheet();
      } catch (error) {
        this.setState({
          loadingButton: false,
        });
        Modal.error({
          title: "Process Error",
          content: error.message,
        });
      }
    } else {
      Modal.error({
        title: "Validation Error",
        content: "Data Table Kosong",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <MainLayout defaultOpenKeys={["m-invoice"]} defaultSelectedKeys={["m-invoice-upload-svb-cost"]}>
          <div style={{ padding: 10, margin: 10, background: "#FFF" }}>
            <br />
            <Row>
              <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                <Form name="form-input" layout="vertical">
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                      <SelectDealer value={this.state.DealerCode} onChangeValue={this.changeDealerCode} />
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                      <Form.Item label="No Tagihan" value={this.state.invoiceNumber} onChange={this.changeInvoiceNumber}>
                        <Input placeholder="No Tagihan" />
                      </Form.Item>
                    </Col>

                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                      <Form.Item label="Link Faktur Pajak">
                        <Input placeholder="Link Faktur Pajak" value={this.state.linkTaxInvoice} onChange={this.changelinkTaxInvoice} />
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                      <Form.Item label="Total DPP">
                        <Input value={this.numberWithCommas(this.state.TotalTaxDPP)} className="text-right text-info" disabled />
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                      <Form.Item label="Total PPN">
                        <Input value={this.numberWithCommas(this.state.TotalTaxPPN)} className="text-right text-info" disabled />
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                      <Form.Item label="Total Tagihan ">
                        <Input value={this.numberWithCommas(this.state.TotalTaxDPP + this.state.TotalTaxPPN)} className="text-right text-info" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                <br />
                <Row>
                  <Col style={{ paddingLeft: 5, paddingRight: 5, marginTop: 5 }}>
                    <Button onClick={this.onClickUploadData} icon={<UploadOutlined />} type="primary" title="Upload Tagihan" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                      Upload Data
                    </Button>
                  </Col>
                  <Col style={{ paddingLeft: 5, paddingRight: 5, marginTop: 5 }}>
                    <Button onClick={this.onFetchTaxData} icon={<RightCircleOutlined />} type="primary" title="Check Data Pajak" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                      Check Data Pajak
                    </Button>
                  </Col>
                  <Col style={{ paddingLeft: 5, paddingRight: 5, marginTop: 5 }}>
                    <Button onClick={this.onResetSheet} icon={<ReloadOutlined />} type="primary" title="Refresh Table" loading={this.state.loadingButton} style={{ background: "#ca2e2e", borderColor: "#ca2e2e" }}>
                      Refresh Table
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                    <br />
                    <Spreadsheet data={this.state.dataSheet} columnLabels={this.columnLabels} onChange={this.onSheetChange} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(ServiceCostUploadInvoice);
